import './App.css';
import ImageGenerator from './Components/ImageGenerator/ImageGenerator';
import LandingPage from './Components/LandingPage';
import React, { useState } from 'react';

function App() {
  const [showImageGenerator, setShowImageGenerator] = useState(false);

  return (
    <div>
      {!showImageGenerator ? (
        <LandingPage onCreateShirt={() => setShowImageGenerator(true)} />
      ) : (
        <ImageGenerator />
      )}
    </div>
  );
}

export default App;
