// import React, {useState, useRef} from 'react';
import React, {useState, useEffect} from 'react';
import './ImageGenerator.css';
import bluedove from '../Assets/bluedove.png';
import background1 from '../Assets/background1.png';
import background2 from '../Assets/background2.png';
import background3 from '../Assets/background3.png';
import background4 from '../Assets/background4.png';
import axios from 'axios';
import whiteTshirt from '../Assets/tshirt_white.png';
import blackTshirt from '../Assets/tshirt_black.png';
import grayTshirt from '../Assets/tshirt_gray.png';
import blueTshirt from '../Assets/tshirt_blue.png';
import redTshirt from '../Assets/tshirt_red.png';

// Add your image assets for visuals (optional)
import sampleDesign1 from '../Assets/sampleDesign1.png'; // Replace with actual image paths
import sampleDesign2 from '../Assets/sampleDesign2.png';
import sampleDesign3 from '../Assets/sampleDesign3.png'; // Replace with actual image paths
import sampleDesign4 from '../Assets/sampleDesign4.png';

const ImageGenerator = () => {

  const [image_url, setImage_url] = useState("/")
  const [inputValue, setInputValue] = useState(''); // State for input value
  const [loading, setLoading] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [finalImage , setFinalImage] = useState(null); // State for final saved image
  const [tshirtColor, setTshirtColor] = useState(null); // Changed initial state to null
  const [page, setPage] = useState('initial'); // State to manage different pages
  // const [prevPage, setPrevPage] = useState('initial'); // Track the previous page
  const [history, setHistory] = useState([]); // Stack to keep track of page history
  // const [showColorOptions, setShowColorOptions] = useState(false); // State to show color options
  const [imageType, setImageType] = useState('');
  const [subject, setSubject] = useState('');
  const [details, setDetails] = useState('');
  // const [style, setStyle] = useState('');
  const [textPosition, setTextPosition] = useState('');
  const [text, setText] = useState('');
  const [font, setFont] = useState('');
  const [fontColor, setFontColor] = useState('');
  const [backgroundColor, setbackgroundColor] = useState('');
  const [additional, setAdditional] = useState('');
  const [prompt, setPrompt] = useState('');
  const [feedback, setFeedback] = useState('');

  // Combine all the values into a single prompt
  const updatePrompt = () => {
    let newPrompt = '';
    if (page === 'hasText'){
      newPrompt = `${imageType} of the text "${text}" written in ${font} typeface, centered in ${fontColor} on ${backgroundColor} background. ${additional}`.trim();
    } else if (page === 'hasImage'){
      newPrompt = `${imageType} ${subject} ${details} on ${backgroundColor} background. ${additional}`.trim();
    } else if (page === 'hasBoth'){
      newPrompt = `${imageType} ${subject} ${details}, with the text "${text}" written in ${font} typeface, centered in ${fontColor} on ${backgroundColor} background. ${additional}`.trim();
    }
    setPrompt(newPrompt);
    // console.log('Generated Prompt:', newPrompt); 
  };

  // Use this effect to update the prompt whenever an input changes
  useEffect(() => {
      updatePrompt();
  }, [imageType, subject, text, font, fontColor, backgroundColor, additional, details, textPosition]);

  useEffect(() => {
    if (page !== 'initial') {
        setHistory(prevHistory => [...prevHistory, page]);
    }
  }, [page]);

  const goBack = () => {
      setHistory(prevHistory => {
          const updatedHistory = [...prevHistory];
          updatedHistory.pop(); 
          const lastPage = updatedHistory.pop(); 
          setPage(lastPage || 'initial'); 
          return updatedHistory;
      });
  };

  const samplePrompts = [
    {
      image: sampleDesign1,
      prompt:
        'A fun whimsical birthday card design featuring a rabbit offering a cake and the text: "Happy Birthday"',
    },
    {
      image: sampleDesign2,
      prompt:
        'The word "Love" written in thin and refined formal script typeface with flourishes, in black on a white background.',
    },
  ];

  const generateImage = async () =>{

    if (inputValue === "" && prompt ==="") {
        return;
    }
    setLoading(true)

    const requestPrompt = page === 'describe' ? inputValue : prompt;
    if (!requestPrompt) {
      console.error('Prompt is empty');
      setLoading(false);
      return;
    }
    // console.log('Prompt being sent to the API:', prompt);
    
    try {
      const response = await fetch(
        "https://bluedovecreation-f12655b62b2c.herokuapp.com/api/generate-image",
        // "http://localhost:8000/api/generate-image",  //this is local testing
        {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
            },
            body:JSON.stringify({
                prompt: requestPrompt,  // Use the `prompt` state directly here
                model: 'V_2_TURBO',
            }),
        }
      );
      if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }
    const data = await response.json();

    // Verify the structure of the response
    if (!data.data || data.data.length === 0) {
      throw new Error("Invalid response structure from generate-image API");
    }
    // console.log('Generated image data:', data); // Log the response data
    const generatedPrompt = data.data[0].prompt;
    const imageUrl = data.data[0].url;

    console.log('Generated Prompt:', generatedPrompt); // Log the generated prompt
    console.log('Generated Image URL:', imageUrl); // Log the image URL

    setGeneratedImage(imageUrl);
    setImage_url(imageUrl);

    setPrompt(generatedPrompt); // Store the original prompt
    setInputValue(generatedPrompt); // Optionally, update inputValue with the generated prompt
    } catch (error){
    console.error('Error generating image:', error);
    } finally {
    setLoading(false)
    }
  }

  const saveImage = async () => {
      if (!generatedImage) return;
      try {
      setFinalImage(generatedImage); // Store the final image URL
      setPage('selectColor'); // Navigate to the color selection page
      } catch (error) {
        console.error('Error saving the image:', error);
      }
  };

  const handleFeedback = async () => {
    if (!feedback) {
      alert('Please describe what you do not like about the image.');
      return;
    }   

    setLoading(true);

    try {
      const response = await fetch(
        'https://bluedovecreation-f12655b62b2c.herokuapp.com/api/refine-prompt', // this is heroku
        // 'http://localhost:8000/api/refine-prompt',  //this is local testing
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            originalPrompt: prompt,
            feedback: feedback,
          }),
        }
      ); 
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }    
      const data = await response.json();
      console.log('Refined Prompt data:', data); // Log the response data
      console.log(data.newPrompt); // Log the response data
      const newPrompt = data.newPrompt;    
      setPrompt(newPrompt);
      setInputValue(newPrompt);     
      // Now generate the image with the new prompt
      await generateImageWithNewPrompt(newPrompt);
    } catch (error) {
      console.error('Error refining prompt:', error);
    } finally {
      setLoading(false);
      setFeedback(''); // Clear the feedback
    }
  };
    
      // Helper function to generate image with the new prompt
      const generateImageWithNewPrompt = async (newPrompt) => {
        if (!newPrompt) {
          return;
        }
        setLoading(true);
        try {
          const response = await fetch(
            'https://bluedovecreation-f12655b62b2c.herokuapp.com/api/generate-image', // this is heroku
          //  'http://localhost:8000/api/generate-image',  //this is local testing
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  prompt: newPrompt,
                  model: 'V_2_TURBO',
              }),
            }
          );
      
          if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }
      
          const data = await response.json();
          const imageUrl = data.data[0].url;
      
          setGeneratedImage(imageUrl);
          setImage_url(imageUrl);
        } catch (error) {
          console.error('Error generating image:', error);
        } finally {
          setLoading(false);
        }
      };
      
    const tshirtTemplates = {
        white: whiteTshirt,
        black: blackTshirt,
        gray: grayTshirt,
        blue: blueTshirt,
        red: redTshirt
      };

    const colorOptions = [
      { name: 'Red', color: 'red' },
      { name: 'Blue', color: 'blue' },
      { name: 'White', color: 'white' },
      { name: 'Gray', color: 'gray' },
      { name: 'Black', color: 'black' },
    ];

    const orderTshirt = () => {
      alert('Order placed for your custom T-shirt!');
      // You can replace this with your order handling logic
    };


  return (
    <div className='ai-image-generator'>

      {page === 'initial' && (
          <>
          {/* Enhanced Header Section */}
          {/* <div className="header-container">
            <div className="header-title">
              Create Unique Custom T-Shirts with Ease
            </div>
            <div className="header-subtitle">
                Use our AI-powered platform to quickly design t-shirts that reflect your ideas, creativity, and style. No hassle, just fun and creativity!
            </div>
          </div> */}

          <div className="examples-section">
            {/* Section 1: Start Designing from Scratch */}
            <div className="section">
              <h2>Start Designing from Scratch</h2>

              <div className="design-row">

              <div className="example">
                <textarea
                className="example-textarea"
                  readOnly
                  value="A fun whimsical birthday card design featuring a rabbit offering a cake and the text: 'Happy Birthday'."
                />
                <img src={sampleDesign1} alt="Sample Design: Rabbit Birthday" />
              </div>
              <div className="example">
                <textarea
                className="example-textarea"
                  readOnly
                  value="The word 'Love' written in thin and refined formal script typeface with flourishes, in black on a white background."
                />
                <img src={sampleDesign2} alt="Sample Design: Love Script" />
              </div>

              </div>

              <div className="button-container">
                <button className="custom-button" onClick={() => setPage('describe')}>
                  Start Designing from Scratch
                </button>
              </div>
            </div>
            {/* Section 2: Get Inspiration and Start Creating */}
            <div className="section">
              <h2>Need Help Getting Started?</h2>

              <div className="design-row">

                <div className="example">
                  <div className="example-description">
                    <strong>Design Type:</strong> An illustration
                    <br />
                    <strong>Subject:</strong> a tropical beach sunset
                    <br />
                    <strong>Details:</strong> with vibrant colors like orange, pink, and purple, and a silhouette of a palm tree
                  </div>
                  <img src={sampleDesign4} alt="Sample Design: Beach Sunset" />
                </div>
                <div className="example">
                  <div className="example-description">
                    <strong>Design Type:</strong> A typography design
                    <br />
                    <strong>Text:</strong> Stay Positive
                    <br />
                    <strong>Font:</strong> bold sans-serif
                    <br />
                    <strong>Text Color:</strong> black
                    <br />
                    <strong>Background Color:</strong> white
                  </div>
                  <img src={sampleDesign3} alt="Sample Design: Stay Positive" />
                </div>

                </div>
                
                <div className="button-container">
                  <button className="custom-button" onClick={() => setPage('help')}>
                    Get Inspiration and Start Creating
                  </button>
                  </div>
              </div>
            </div>
              {/* <div className="header">Create your <span>design</span></div>
              <div className="button-container">
                  <button className="option-btn" onClick={() => setPage('describe')}>
                      Describe my design by myself
                  </button>
                  <button className="option-btn" onClick={() => setPage('help')}>
                      I need help to describe my design
                  </button>
              </div> */}
          </>
      )}

      {page === 'describe' && (
        <div id="describe-section">
            <button className="back-btn" onClick={goBack}>
                ← Back
            </button>
            <h2>Create Your Design</h2>
            <div> 
               {/* <label htmlFor="subject">Describe your design</label> */}
                <textarea id="description-textarea" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder='Describe Your Design' rows="4"/>
                <div className="generate-btn" onClick={generateImage}>Generate an image</div> 
            </div>

            <div className="img-loading">
            <div className="loading">
                    <div className={loading?"loading-bar-full":"loading-bar"}></div>
                    <div className={loading?"loading-text":"display-none"}>Loading...</div>
                </div>
                <div className="image"><img src={image_url==="/"?background3:image_url} alt="Generated Design"/>
                </div>
            </div>
            {/* {generatedImage && (<div className="save-btn" onClick={saveImage}>Select the image</div> )} */}
            {generatedImage && (
              <>
                {/* <div className="img-loading">
                  <div className="image">
                    <img src={image_url === '/' ? background3 : image_url} alt="" />
                  </div>
                </div> */}
                <div className="button-group">
                  <div className="save-btn" onClick={saveImage}>
                    Select the image
                  </div>
                  <div className="feedback-option">
                    <p>If you don't like the image, describe what you don't like:</p>
                    <textarea
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                      placeholder="Describe what you don't like about the image"
                      rows="4"
                    />
                    <div className="generate-btn" onClick={handleFeedback}>
                      Generate a new image
                    </div>
                  </div>
                </div>
              </>
            )}

        </div>
      )}

      {page === 'help' && (
        <>
          <button className="back-btn" onClick={goBack}>
            ← Back
          </button>
          <div className="button-container">
              <button className="option-btn" onClick={() => setPage('hasBoth')}>
                  My design has images and text
              </button>
              <button className="option-btn" onClick={() => setPage('hasImage')}>
                  My design only has images
              </button>
              <button className="option-btn" onClick={() => setPage('hasText')}>
                  My design only has text
              </button>
          </div>
          </>
      )}  
      {/* New pages for each option */}
      {page === 'hasBoth' && (
        <>
          <button className="back-btn" onClick={goBack}>
              ← Back
          </button>
          <div className="prompt-generator">
            <h2>Create Your Design</h2>
            <div className="tag-container">
              <div className="tag-input-select">
              <label htmlFor="subject">Design type</label>
              <select value={imageType} onChange={(e) => setImageType(e.target.value)}>
                  <option value="">Select...</option>
                  <option value="An image of">An image of</option>
                  <option value="A sketch of">A sketch of</option>
                  <option value="A photograph of">A photograph of</option>
                  <option value="A 3D rendering of">A 3D rendering of</option>
                  <option value="An illustration of">An illustration of</option>
                  <option value="A drawing of">A drawing of</option>
                  <option value="A picture of">A picture of</option>
                  <option value="A cubist painting of">A cubist painting of</option>
              </select>
            </div>

            <div className="tag-input">
            <label htmlFor="subject">Subject</label>
                <input 
                    type="text" 
                    placeholder="ex) a cat, a president, etc" 
                    value={subject} 
                    onChange={(e) => { setSubject(e.target.value); updatePrompt(); }} 
                />
            </div>
            <div className="tag-input">
            <label htmlFor="details">Details</label>
                <input 
                    type="text" 
                    placeholder="ex) with bright blue eyes, wearing a navy suit, etc" 
                    value={details} 
                    onChange={(e) => { setDetails(e.target.value); updatePrompt(); }}
                />
            </div>
            <div className="tag-input-text">
            <label htmlFor="text">Text</label> 
                    <input 
                        type="text" 
                        placeholder="ex) I am happy, God loves you, etc" 
                        value={text} 
                        onChange={(e) => { setText(e.target.value); updatePrompt(); }}
                    />
                </div>
                {/* <div className="tag-container-inline"> */}
                <div className="tag-input">
                <label htmlFor="font">Font</label> 
                    <input 
                        type="text" 
                        placeholder="ex) bold arial, italic roboto, etc" 
                        value={font} 
                        onChange={(e) => { setFont(e.target.value); updatePrompt(); }} 
                    />
                </div>
                <div className="tag-input">
                <label htmlFor="text color">Text color</label> 
                    <input 
                        type="text" 
                        placeholder="ex) black, blue, red, etc" 
                        value={fontColor} 
                        onChange={(e) => { setFontColor(e.target.value); updatePrompt(); }}
                    />
                </div>
                <div className="tag-input">
                <label htmlFor="background color">Background Color</label> 
                    <input 
                        type="text" 
                        placeholder="ex) white, grey, black, etc" 
                        value={backgroundColor} 
                        onChange={(e) => { setbackgroundColor(e.target.value); updatePrompt(); }}
                    />
                </div>
                <div className="tag-input-additional">
                <label htmlFor="additional description">Additional Description</label> 
                    <input 
                        type="text" 
                        placeholder="Addtional description" 
                        value={additional} 
                        onChange={(e) => { setAdditional(e.target.value); updatePrompt(); }}
                    />
                </div>
            </div>
          </div>


          <div className="prompt-display">
                <textarea value={prompt} readOnly rows="4" />
            </div>

          <div> 
              {/* <textarea id="description-textarea" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder='Describe What You Want To See' rows="4"/> */}
              <div className="generate-btn" onClick={generateImage}>Generate an image</div> 
          </div>



          {/* <button className="prompt-ubtn" onClick={handleGeneratePrompt}>Generate Prompt</button>

          <div> 
              <textarea id="description-textarea" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder='Describe What You Want To See' rows="4"/>
              <div className="generate-btn" onClick={generateImage}>Generate an image</div> 
          </div> */}

          <div className="img-loading">
            <div className="loading">
                <div className={loading?"loading-bar-full":"loading-bar"}></div>
                <div className={loading?"loading-text":"display-none"}>Loading...</div>
            </div>
            <div className="image"><img src={image_url==="/"?background3:image_url} alt="Generated Design"/>
            </div>
          </div>

          {/* {generatedImage && (<div className="save-btn" onClick={saveImage}>Select the image</div> )}   */}
          {generatedImage && (
              <>
                {/* <div className="img-loading">
                  <div className="image">
                    <img src={image_url === '/' ? background3 : image_url} alt="" />
                  </div>
                </div> */}
                <div className="button-group">
                  <div className="save-btn" onClick={saveImage}>
                    Select the image
                  </div>
                  <div className="feedback-option">
                    <p>If you don't like the image, describe what you don't like:</p>
                    <textarea
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                      placeholder="Describe what you don't like about the image"
                      rows="4"
                    />
                    <div className="generate-btn" onClick={handleFeedback}>
                      Generate a new image
                    </div>
                  </div>
                </div>
              </>
            )}

        </>
      )}

      {page === 'hasImage' && (
        <>
          <button className="back-btn" onClick={goBack}>
              ← Back
          </button>
          <div className="prompt-generator">
          <h2>Create Your Design</h2>
            <div className="tag-container">
              <div className="tag-input-select">
              <label htmlFor="subject">Design type</label>
              <select value={imageType} onChange={(e) => setImageType(e.target.value)}>
                  <option value="">Select...</option>
                  <option value="An image of">An image of</option>
                  <option value="A sketch of">A sketch of</option>
                  <option value="A photograph of">A photograph of</option>
                  <option value="A 3D rendering of">A 3D rendering of</option>
                  <option value="An illustration of">An illustration of</option>
                  <option value="A drawing of">A drawing of</option>
                  <option value="A picture of">A picture of</option>
                  <option value="A cubist painting of">A cubist painting of</option>
              </select>
            </div>

            <div className="tag-input">
            <label htmlFor="subject">Subject</label> 
                <input 
                    type="text" 
                    placeholder="ex) a cat, a president, etc" 
                    value={subject} 
                    onChange={(e) => { setSubject(e.target.value); updatePrompt(); }} 
                />
            </div>
            <div className="tag-input">
            <label htmlFor="details">Details</label> 
                <input 
                    type="text" 
                    placeholder="ex) with bright blue eyes, wearing a navy suit, etc" 
                    value={details} 
                    onChange={(e) => { setDetails(e.target.value); updatePrompt(); }}
                />
            </div>
            <div className="tag-input">
            <label htmlFor="background color">Background Color</label> 
                    <input 
                        type="text" 
                        placeholder="ex) white, grey, black, etc" 
                        value={backgroundColor} 
                        onChange={(e) => { setbackgroundColor(e.target.value); updatePrompt(); }}
                    />
                </div>
            {/* <div className="tag-input">
                <input 
                    type="text" 
                    placeholder="Style" 
                    value={style} 
                    onChange={(e) => { setStyle(e.target.value); updatePrompt(); }}
                />
            </div> */}
            <div className="tag-input-additional">
            <label htmlFor="additional description">Additional Description</label> 
                    <input 
                        type="text" 
                        placeholder="Addtional description" 
                        value={additional} 
                        onChange={(e) => { setAdditional(e.target.value); updatePrompt(); }}
                    />
                </div>
          </div>
      
          {/* <button className="prompt-ubtn" onClick={handleGeneratePrompt}>Generate Prompt</button> */}
          </div>

          <div className="prompt-display">
                <textarea value={prompt} readOnly rows="4" />
            </div>

          <div> 
              {/* <textarea id="description-textarea" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder='Describe What You Want To See' rows="4"/> */}
              <div className="generate-btn" onClick={generateImage}>Generate an image</div> 
          </div>

          <div className="img-loading">
          <div className="loading">
              <div className={loading?"loading-bar-full":"loading-bar"}></div>
              <div className={loading?"loading-text":"display-none"}>Loading...</div>
          </div>
              <div className="image"><img src={image_url==="/"?background3:image_url} alt="Generated Design"/>
              </div>
          </div>

          {/* {generatedImage && (<div className="save-btn" onClick={saveImage}>Select the image</div> )}   */}
          {generatedImage && (
              <>
                {/* <div className="img-loading">
                <div className="image">
                  <img src={image_url === '/' ? background3 : image_url} alt="" />
                </div>
              </div> */}
                <div className="button-group">
                  <div className="save-btn" onClick={saveImage}>
                    Select the image
                  </div>
                  <div className="feedback-option">
                    <p>If you don't like the image, describe what you don't like:</p>
                    <textarea
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                      placeholder="Describe what you don't like about the image"
                      rows="4"
                    />
                    <div className="generate-btn" onClick={handleFeedback}>
                      Generate a new image
                    </div>
                  </div>
                </div>
              </>
            )}

        </>
      )}

      {page === 'hasText' && (
        <>
          <button className="back-btn" onClick={goBack}>
              ← Back
          </button>
          <div className="prompt-generator">
          <h2>Create Your Design</h2>
            <div className="tag-container">
                <div className="tag-input-select">
                <label htmlFor="subject">Design type</label>
                    <select value={imageType} onChange={(e) => setImageType(e.target.value)}>
                        <option value="">Select...</option>
                        <option value="A typography">A typography </option>
                        <option value="An illustration">An illustration </option>
                        <option value="A typography design">A typography design </option>
                        <option value="A typography illustration">A typography illustration </option>
                    </select>
                </div>
                {/* <span> of the text " </span> Fixed text */}
                <div className="tag-input-text">
                <label htmlFor="text">Text</label> 
                    <input 
                        type="text" 
                        placeholder="ex) I am happy, God loves you, etc" 
                        value={text} 
                        onChange={(e) => { setText(e.target.value); updatePrompt(); }}
                    />
                </div>
                {/* <div className="tag-container-inline"> */}
                <div className="tag-input">
                <label htmlFor="font">Font</label> 
                    <input 
                        type="text" 
                        placeholder="ex) bold arial, italic roboto, etc" 
                        value={font} 
                        onChange={(e) => { setFont(e.target.value); updatePrompt(); }} 
                    />
                </div>
                <div className="tag-input">
                <label htmlFor="text color">Text Color</label> 
                    <input 
                        type="text" 
                        placeholder="ex) black, blue, red, etc" 
                        value={fontColor} 
                        onChange={(e) => { setFontColor(e.target.value); updatePrompt(); }}
                    />
                </div>
                <div className="tag-input">
                <label htmlFor="background color">Background Color</label> 
                    <input 
                        type="text" 
                        placeholder="Background Color" 
                        value={backgroundColor} 
                        onChange={(e) => { setbackgroundColor(e.target.value); updatePrompt(); }}
                    />
                </div>
                <div className="tag-input-additional">
                <label htmlFor="additional description">Additional Description</label> 
                    <input 
                        type="text" 
                        placeholder="Addtional description" 
                        value={additional} 
                        onChange={(e) => { setAdditional(e.target.value); updatePrompt(); }}
                    />
                </div>
            </div>

            {/* <div className="generate-btn" onClick={generateImage}>Generate an image</div>  */}
           
          </div>
      
          <div className="prompt-display">
                <textarea value={prompt} readOnly rows="4" />
            </div>

          {/* <button className="prompt-ubtn" onClick={handleGeneratePrompt}>Generate Prompt</button> */}

          <div> 
              {/* <textarea id="description-textarea" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder='Describe What You Want To See' rows="4"/> */}
              <div className="generate-btn" onClick={generateImage}>Generate an image</div> 
          </div>

          <div className="img-loading">
          <div className="loading">
              <div className={loading?"loading-bar-full":"loading-bar"}></div>
              <div className={loading?"loading-text":"display-none"}>Loading...</div>
            </div>
            
              <div className="image"><img src={image_url==="/"?background3:image_url} alt="Generated Design"/>
              </div>
          </div>

          {/* {generatedImage && (<div className="save-btn" onClick={saveImage}>Select the image</div> )}   */}
          {generatedImage && (
              <>
                {/* <div className="img-loading">
                  <div className="image">
                    <img src={image_url === '/' ? background3 : image_url} alt="" />
                  </div>
                </div> */}
                <div className="button-group">
                  <div className="save-btn" onClick={saveImage}>
                    Select the image
                  </div>
                  <div className="feedback-option">
                    <p>If you don't like the image, describe what you don't like:</p>
                    <textarea
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                      placeholder="Describe what you don't like about the image"
                      rows="4"
                    />
                    <div className="generate-btn" onClick={handleFeedback}>
                      Generate a new image
                    </div>
                  </div>
                </div>
              </>
            )}

        </>
      )}

      {page === 'selectColor' && finalImage && (
        <>
        {/* <div className="next-page"> */}
          <div className="color-picker">
            <div className="select-tshirt-header">Select T-shirt Color:</div>
            <div className="color-options">
              {colorOptions.map((option) => (
                  <button key={option.name} style={{ backgroundColor: option.color }} className="color-option" onClick={() => setTshirtColor(option.color)} aria-label={option.name}>
                  </button>
              ))}
            </div>
          </div>
      
          {tshirtColor && (
            <div className="tshirt-preview-container">
              <div className="tshirt-preview" style={{ backgroundImage: `url(${tshirtTemplates[tshirtColor]})` }}>
                <img src={finalImage} alt="T-shirt design" className="tshirt-image"/>
              </div>
              <div className="order-btn-container">
                <button className="order-btn" onClick={orderTshirt}>Order</button>
              </div>  
            </div>
            )
          }
     </>
        // </div>
      )}
    </div>
  )
}
export default ImageGenerator
